*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Added */
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: white;
}

.ant-modal-content {
  border-radius: 10px;
  min-width: 350px;
  margin-inline: auto;
}

.center {
  text-align: center !important;
  margin: 0 auto;
}

.btn-mtks {

  height: 43px;
  border-radius: 50px !important;
  border: none !important;
  background-color: #af0f7d;
  background-image: linear-gradient(25deg, #af0f7d, #58086c) !important;
}

.nav-btn-vsion[data-active] {
  border-radius: 50px !important;
  border: none !important;
  background-color: #af0f7d;
  background-image: linear-gradient(25deg, #af0f7d, #58086c) !important;
}

.nav-btn-vsion:hover {
  border-radius: 50px !important;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-table-wrapper .ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgb(255 255 255 / 88%);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  background: #0f0a1d;
  border-radius: 8px 8px 0 0;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-pr0fja).ant-table-wrapper .ant-table-thead>tr>td {
  position: relative;
  color: rgb(255 255 255);
  font-weight: 600;
  text-align: start;
  background: #af117d;
  border-bottom: 1px solid #363b4d;
  transition: background 0.2s ease;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-table-wrapper .ant-table-tbody>tr>th,
:where(.css-dev-only-do-not-override-pr0fja).ant-table-wrapper .ant-table-tbody>tr>td {
  transition: background 0.2s, border-color 0.2s;
  border-bottom: 1px solid #363c4f;
}

:where(.css-dev-only-do-not-override-pr0fja).ant-table-wrapper .ant-table-tbody .ant-table-row>.ant-table-cell-row-hover {
  background: #af127d;
}

.tpendi {
  position: relative;
  top: -218px;
}

.titl,
h3 {
  font-family: Inter, system-ui, sans-serif;
  color: #00bcd4;
  font-weight: bold;
}

.tw-connected-wallet__balance {
  color: rgb(255 255 255) !important;
  font-weight: bold !important;
}

.bg-prizepool {
  margin: 10px;
  padding: 15px;
  border-radius: 10px;
  border: 3px solid #fff;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.75);
  background: linear-gradient(90deg, #980e78, rgb(83 6 111));
}

.bg-prizepool:hover {
  background: #03e9f4;
  color: #050801;
  box-shadow: 0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4, 0 0 200px #03e9f4;

}

.ball-number {
  background: #f0f0f0;
  height: 70px;
  width: 70px;
  text-align: center;
  color: rgb(15, 7, 29);
  outline: 2px rgb(15, 7, 29);
}

.neon-bg-vsion {
  box-shadow: 0 0 2px rgb(175, 15, 125), 0 0 6px rgb(153, 0, 209), 0 0 50px rgb(153, 0, 209), 0 0 200px rgb(175, 15, 125);
}

.bg-lotto {
  background: url("https://pixner.net/jugaro/jugaro/assets/images/bc/games.png");
  min-height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

._footer {
  padding: 0px;
  background: rgb(32 9 68) url(https://pixner.net/jugaro/jugaro/assets/images/footerbg.jpg);
  background-repeat: no-repeat;
  background-position: bottom;
}

.bg-lot {
  background: url("https://casina.codexcoder.com/assets/images/match/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.no-repeat {
  margin-top: -70px !important;
  min-height: 100vh;
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  background-attachment: fixed;
}

.box-lotto {
  border: 3px solid #F7BAFF;
  opacity: 0.98;
  border-radius: 20px;
  backdrop-filter: blur(8.374699592590332px);
  --webkit-backdrop-filter: blur(8.374699592590332px);
  background-color: rgba(255, 255, 255, 0.13);
}

.title-lotto {
  text-align: left;
  font-weight: bold;
}

.bg-ball {
  padding: 10px;
  border-radius: 18px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.0) 9%, #a337fb 30%, rgb(161 15 122) 68%, rgba(241, 241, 243, 0.0) 83%)
}

.ticketLotto {
  width: 220px;
  height: 110px;
  background-size: 100%;
  background-repeat: no-repeat;
  right: 0;
  padding-top: 17px;

}

.buyticket {
  position: absolute;
  right: -70px;
  top: 60px;
  height: 200px;
}

.btn-buy-lotto {
  border: 1px solid #fc9700;
  border-radius: 50px !important;
}

.last-lotto {
  background: transparent;
  border: 3px solid #f7baff;
  opacity: 0.98;
  /* Note: currently only Safari supports backdrop-filter */
  backdrop-filter: blur(8.374699592590332px)
}

.color-white {
  color: #fff !important;
}

.fopa {
  filter: grayscale(1);
  opacity: 0.9;
}

#dexscreener-embed {
  position: relative;
  width: 100%;
  padding-bottom: 125%;
}

@media(min-width:1400px) {
  #dexscreener-embed {
    padding-bottom: 65%;
  }
}

#dexscreener-embed iframe {
  position: absolute;
  width: 100%;
  height: 80%;
  top: 0;
  left: 0;
  border: 0;
}

.center-bull {
  text-align: center;
  margin: 0 auto;
}

#dexscreener-embed iframe a[href="/bsc/0xBADd8eEB7b4D12c8CaD00bC856BaA79874Fa814E"] {
  display: none !important;
}

.bg-trade {
  background-position: 400px center;
  min-height: 600px;
  background-size: 100% 100%;
  background-repeat: no-repeat !important;
  background-attachment: fixed;
}