.conteiner {
  transition: display 3s, width 3s, height 3s, border-radius 3s, overflow 3s, gap 3s;
}

.conteiner:hover .btn {
  display: flex;
}

.conteiner:hover .img {
  width: 275px;
  height: 230px;
  border-radius: 14px;
  overflow: hidden;

}

.imgNFt {
  border-radius: 14px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.conteiner:hover .cont {
  gap: 20px;
}

.conteiner .btn {
  border-radius: 50px;
}